import React from 'react';
import { Base } from '../components/base';
import Helmet from 'react-helmet';

export default class Layout extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  render = () => (
    <Base>
      <Helmet>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <title>Native Nation</title>
      </Helmet>
      { this.props.children }
    </Base>
  );
}
