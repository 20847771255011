import React from 'react';

import Facebook from '../navigation/facebook.inline.svg';
import Instagram from '../navigation/instagram.inline.svg';

export class Footer extends React.Component {
  constructor () {
    super();
    this.state = {};
  }

  render () {
    return (
      <footer>
        <div className="footer__left flex">
          <a href="https://www.facebook.com/nativenationeu" target="_blank"><Facebook /></a>
          <a href="https://www.instagram.com/nativenationeu/" target="_blank"><Instagram /></a>
        </div>
        <div className="footer__right">
          <div>© Native Nation BVBA - Harensesteenweg 224, 1800 Vilvoorde - BE0651.632.241</div>
          <div><a href="/pdf/terms.pdf" target="_blank">Terms and Conditions</a></div>
          <div><a href="/pdf/privacy.pdf" target="_blank">Privacy Policy</a></div>
        </div>
      </footer>
    );
  }
}



