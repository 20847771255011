import React from 'react';

import Link from '../../contentful-engine/link';

import Logo from './logo.inline.svg';
import Facebook from './facebook.inline.svg';
import Instagram from './instagram.inline.svg';
import Cross from './cross.inline.svg';

export class Navigation extends React.Component {
  constructor () {
    super();
    this.state = { open: false };
  }

  toggle = () => {
    this.setState({ open: !this.state.open }, () => {
      if (this.state.open) document.addEventListener('click', this.toggle);
      else document.removeEventListener('click', this.toggle);
    });
  }

  get links () {
    return (
      <React.Fragment>
        <Link to='/about'>About</Link>
        <Link to='/cases'>Work</Link>
        <Link to='/services'>Our services</Link>
        { /* <Link to='/team'>Team</Link> */ }
        { /* <Link to='/blog'>Blog</Link> */ }
        <Link to='/jobs'>Jobs</Link>
        <Link to='/contact'>Contact</Link>
        <Link to='/faq'>FAQ</Link>
        <Link to='https://admin.nativenation.eu/influencer'>Login</Link>
        { /* <Link to='https://admin.nativenation.eu/influencer'>Register</Link> */ }
      </React.Fragment>
    );
  }

  get social () {
    return (
      <React.Fragment>
        <a href="https://www.facebook.com/nativenationeu" target="_blank"><Facebook /></a>
        <a href="https://www.instagram.com/nativenationeu/" target="_blank"><Instagram /></a>
      </React.Fragment>
    );
  }


  click = () => {
    if (this.state.open) this.toggle();
  }

  componentDidMount() {
      window.addEventListener('scroll', () => {
          if (window.scrollY > 50) {
              return document.querySelector('header').classList.add('scrolled')
          }
          return document.querySelector('header').classList.remove('scrolled')
      });
  }

    render () {
    return (
      <React.Fragment>
        <header>
          <Link to='/'><Logo /></Link>
          <nav className="nav--desktop">
            { this.links }
            <span className="nav__social">{ this.social }</span>
          </nav>

          <nav className="nav--mobile"><div className="hamburger" onClick={this.toggle} /></nav>

          { this.state.open && <div className="nav__overlay">
            <div className="nav__overlay__top">
              <Link to='/'><Logo /></Link>
              <Cross onClick={this.toggle} />
            </div>
            <div className="nav__overlay__links">
              { this.links }
            </div>

            <div className="nav__overlay__social">
              { this.social }
            </div>
          </div> }
        </header>
      </React.Fragment>
    );
  }
}