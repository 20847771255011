import React from 'react';

import './_index.scss';
import {AsyncCss} from '../../contentful-engine/async-css';
import {Navigation} from '../navigation';
import {Footer} from '../footer';

export class Base extends React.Component {
    constructor() {
        super();
        this.state = {};
    }

    render = () => (
        <React.Fragment>
            <AsyncCss url="https://use.typekit.net/uxj8amq.css"/>
            <AsyncCss url="https://fonts.googleapis.com/css?family=Poppins:600&display=swap"/>
            <AsyncCss url="https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap"/>
            <AsyncCss url="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"/>
            <Navigation/>
            {this.props.children}
            <Footer/>
        </React.Fragment>
    );
}